import { cateringRequest, cateringRequestHide, cateringUploadsHide } from '@/utils/fetch'
import { uploadFileItem } from './base'

interface saveReq {
  id?: string
  staff_id?: string
  project_id: string
  start_date: string
  end_date: string
  liable_person: string //现场负责人
  phone: string // 联系电话
  explain: string // 其他说明
  address: string // 地址
  pics?: Array<uploadFileItem> // 图片
}
export type saveProject = saveReq //导出请求保存的数据结构

// 项目发货申请 添加接口
export function DeliverySave(data: saveReq) {
  const url = '/api/project_delivery/save'
  return cateringRequest.post(url, data)
}
